/* .App {
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.product-image-preview {
  width: 100%;
  height: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  /* overflow: hidden; */
}

body {
  width: 100vw;
  height: 100vh;
}
#chat-list-div {
/*        background-color: blue;*/
  height: 100%;
}
#conversation-window-div {
  height: 100%;
}
#top-bar-div {
/*        background-color: yellow;*/
  min-height: 70px;
}
#search-bar-div {
  background-color: lightgrey;
  min-height: 50px;
}
#list-div {
/*        background-color: green;*/
}
#conversation-top-row {
  background-color: gold;
  min-height: 70px;
}
#conversation-window {
  background-color: #efeae2;
}
#conversation-input-row {
  background-color: silver;
}
.conversation-row {
  background-color: white;
  /* border: 1px solid grey; */
  cursor: pointer;
  /* height: 80px;
  width: 100%; */
}

.conversation-row:hover {
  background-color: #ebebeb;
}

.conversation-message-bubble {
  /* background-color: lightblue; */
  border-radius: 5px;
  margin: 2px 0;
  max-width: 50%;
  padding: 5px;
}

.to-msg {
  background-color: #D9FDD3;
}

.from-msg {
  background-color: white;
}

.chat-message {
  /*text-align: right;*/
}

.profile-pic {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.msg-time {
  font-size: 11px;
  color: #8696A0;
  align-self: center;
}

.conversation-message-row.flex-row-reverse .msg-time {
  text-align: right;
}

#emoji-container {
  width: 100%;
  height: 25vh;
  background-color: #f0f2f5;
}

#emoji-cat-container {
  background-color: #f0f2f5;
  padding: 10px 0;
  position: sticky;
  top: 0;
}

.emoji-option {
  /* background-color: lightblue; */
  cursor: pointer;
  /* height: 32px; */
  width: 32px;
  /* margin: 3px; */
  margin-left: 6px;
  font-size: 30px;
  margin-right: 6px;
}

.emoji-button{
  color: grey
}

.active, .emoji-button:hover {
  color: #667781;
}

.conversation-name,
.last-msg-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conversation-name {
  font-size: 16px;
}

.last-msg-line {
  font-size: 14px;
}

.last-msg-time {
  font-size: 12px;
}

.has-unread-msg .unread-badge {
  display: block;
}
.unread-badge {
  white-space: nowrap;
  margin-left: auto;
  background-color: rgb(0, 185, 0);
  font-size: 0.8rem;
  color: white;
  border-radius: 1.1em;
  /* display: none; */
  padding: 0.1rem 0.6em;
  white-space: nowrap;
}

.conversation-row .dropdown {
  display: none;
  margin-left: 8px;
}
.conversation-row:hover .dropdown {
  display: block;
}

.conversation-message-row .dropdown {
  display: none;
  margin-left: 8px;
  position: absolute;
  right: -5px;
  top: -5px;
}

.conversation-message-row:hover .dropdown {
  display: block;
}

.msg-header,
.msg-body,
.msg-footer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow-wrap: anywhere;
}

.msg-header {
  font-size: 15px;
  font-weight: 600;
}

.msg-body {
  font-size: 14.2px;
}

.msg-text code {
  padding: initial;
  font-size: inherit;
  color: initial;
  background-color: initial;
  border-radius: initial;
}

.msg-footer {
  font-size: 13px;
}

.forward_dropdown_left{
  position: absolute;
  inset: 0px 0px auto auto; 
  margin: 0;
  transform: translate3d(0px, 29px, 0px);
}

.forward_dropdown_right{
  position: absolute;
  inset: 0px 0px auto auto; 
  margin: 0;
  transform: translate3d(130px, 29px, 0px);
}

.emoji-picker ul{
  width: 100%;
  margin: auto;
}

.message-template-modal {
  max-width: 80%;
}

/* Pagination Style fix */
ul .pagination .page-item .page-link {
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

.msg-reaction {
  background-color: darkgrey;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 25px;
  cursor: pointer;
  position: relative;
  margin-bottom: -20px;
  float: right;
  display: flex;
}

.msg-reaction-from {
  padding: 0 3px
}

.msg-reaction-to {
  padding: 0 3px
}

.msg-reaction-text {
  font-size: large;
  padding: 0 3px;
}

.msg-sticker {
  max-height: 100%;
  max-width: 100%;
  width: 140px
}

.msg-image {
  max-height: 500px;
  max-width: 300px;
}

.btn-emoji {
  background-color: darkgrey;
  border-radius: 30px;
  padding: 5px 5px;
  /* margin-left: 5px; */
  display: flex;
  justify-content: center;
  cursor: pointer;
}

/* hover for recently reaction */
.emoji-hover {
  background-color: darkgrey;
  display: flex;
  height: fit-content;
  justify-content: space-evenly;
  padding: 0 5px;
  border-radius: 30px;
  /* transform: scale(1.5); */
}

.emoji-hover-send {
  position: absolute;
  right: 0;
}
.emoji-hover-receive {
  position: absolute;
  left: 0;
}

.modal{
    display: block !important; /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    max-height: 80vh;
    overflow-y: auto;
}
.navbar{
  position: sticky;
  top: 0;
  z-index: 10;
}
.btn-floating {
  position: relative;
}
.btn-floating::after {
  box-shadow: 0 1px 2px rgba(11, 20, 26,.17), 0 6px 8px rgba(11, 20, 26,.29);
  content: '';
  position: absolute;
  width: 49px;
  height: 49px;
  top: 2px;
  z-index: 100;
  left: 2px;
  border-radius: 50%;
}
.attachment-popup{
  display: flex;
  flex-direction: column;
  background: transparent;
  width: max-content;
  position: absolute;
  bottom: 75px;
  left: 34px;
}

.attachment-popup div{
  background:transparent  !important;
  width: max-content;
  margin: 8px
}

#conversation-window-div {
  position: relative;
}

#messageTextArea {
  border-radius: 5px;
}

.message-form {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.left-button-group button {
  margin-left: 3px;
  margin-right: 3px;
}

.table td {
  vertical-align: bottom;
}

.table ul {
  margin-bottom: 0px;
  padding-left: 1rem;
}

.msg-marked {
  margin-left: auto;
  padding: 0 5px;
  font-size: 11px;
  height: 15px;
  line-height: 15px;
  /* width: 16px; */
}

.slider-container-marked-message {
  /* height: calc(100vh - 50px); */
  /* margin-top: calc(12vh - 55px); */
  position: absolute !important;
}

.body-marked-message {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  cursor: pointer;
}

.body-marked-message-default {
  background-color: #ebebeb;
}

.body-marked-message:hover{
  background-color: #ebebeb;
}

.sliderHeader-marked-message {
  padding: 25px 15px;
  background-color: transparent;
  background-color: #f1e3cb;
}

.sliderBody-marked-message {
  display: flex;
  background-color: #f1e3cb;
  flex-direction: column;
  padding: 0px 30px;
}

.loading-marked-message {
  align-items: center;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.searchMessageInput {
  width: 100%;
}

.search-slide-container {
  display: flex;
  flex-direction: column;
}

.search-slide-row {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #e9edef;
}

.search-slide-row:hover {
  background-color: #ebebeb;
}

.search-slide-text {
  padding: 5px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn-textarea-function {
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-textarea-function:hover {
  background-color: #eeeeee !important;
  border-color: #eeeeee !important;
}

.dropup {
  top: auto;
  bottom: 200%;
}